@import 'utils/colors.scss';

.BasketInfo {
    display: flex;
    flex-direction: column;
    width: 100%;

    hr {
        border: none;
        border-top: 1px solid #ccc; 
        margin: 20px -40px 15px; // 40px like the modal
    }

    &__Item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    &__LastItem {
        padding: 0px;
    }

    &__Price {
        color: $text-light-gray;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: baseline;
    }

    &__DeleteItem {
        cursor: pointer;
        &:hover {
            color: $default-red;
        }
    }

    &__FinalPrice {
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}